<template>
    <v-form v-model="valid" class="form-custom-disabled">
        <v-row>
            <v-alert
                align="center"
                justify="center"
                dense
                outlined
                type="error"
                class="mt-6 ml-3 mr-3"
                v-if="typeForm === 'new'"
            >
                Este documento não foi encontrado em nossa base de dados. Realize seu cadastro
                abaixo.
            </v-alert>
        </v-row>
        <v-row>
            <v-col cols="12" sm="12" md="6">
                <v-text-field
                    color="primary"
                    v-model="form.name"
                    :rules="[rules.required]"
                    label="Razão Social *"
                    class="v-input-default"
                    :disabled="typeForm !== 'new'"
                    :validate-on-blur="true"
                ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="6">
                <v-text-field
                    color="primary"
                    v-model="form.document"
                    :rules="[rules.required]"
                    label="Document CPF/CNPJ *"
                    class="v-input-default"
                    disabled
                    readonly
                ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="6">
                <v-text-field
                    color="primary"
                    v-model="form.nature"
                    :rules="[rules.required]"
                    label="Natureza *"
                    class="v-input-default"
                    :disabled="typeForm !== 'new'"
                ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="6">
                <v-select
                    color="primary"
                    :items="segmentsList"
                    label="Risco *"
                    :rules="[rules.required]"
                    v-model="form.segment"
                    v-if="typeForm === 'new'"
                    class="v-input-default"
                ></v-select>

                <v-text-field
                    color="primary"
                    v-model="form.segment"
                    label="Risco *"
                    class="v-input-default"
                    :rules="[rules.required]"
                    v-if="typeForm !== 'new'"
                    :disabled="typeForm !== 'new'"
                ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="6" v-if="typeForm === 'new'">
                <v-menu
                    ref="menuVencimento"
                    v-model="menuVencimento"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="form.startAt"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            class="v-input-default"
                            color="primary"
                            v-model="form.startAt"
                            label="Início relacionamento *"
                            prepend-icon="event"
                            readonly
                            v-on="on"
                            :rules="[rules.required]"
                            :disabled="typeForm !== 'new'"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-if="menuVencimento"
                        v-model="date"
                        no-title
                        scrollable
                        locale="pt-br"
                    >
                        <v-spacer></v-spacer>
                        <v-btn color="secondary" depressed @click="menuVencimento = false">Cancelar</v-btn>
                        <v-btn color="secondary" depressed @click="$refs.menuVencimento.save(form.startAt)"
                            >Salvar</v-btn
                        >
                    </v-date-picker>
                </v-menu>
            </v-col>

            <v-col cols="12" sm="12" md="6" v-if="typeForm !== 'new'">
                <v-text-field
                    color="primary"
                    :value="formatDate(form.startAt)"
                    label="Início relacionamento *"
                    class="v-input-default"
                    :disabled="typeForm !== 'new'"
                ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="6">
                <v-text-field
                    color="primary"
                    v-model="form.zipCode"
                    label="CEP *"
                    class="v-input-default"
                    :rules="[rules.required]"
                    :disabled="typeForm !== 'new'"
                    v-mask="'#####-###'"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row class="form-footer" v-if="typeForm === 'new'">
            <v-btn title="cadastrar" color="secondary" :disabled="!valid" depressed @click="submit">Cadastrar</v-btn>
        </v-row>
    </v-form>
</template>

<script>
import { formatarDataBarra } from '@/helpers/data';

export default {
    props: {
        segmentsList: {
            type: Array,
            default: () => [],
        },
        typeForm: {
            type: String,
        },
        currentDocument: {
            type: Object,
        },
    },
    data() {
        return {
            valid: false,
            form: {
                ...this.currentDocument,
            },
            menuVencimento: false,
            rules: {
                required: value => !!value || 'Campo obrigatório',
            },
            date: null,
        };
    },
    watch: {
        date() {
            this.form.startAt = this.formatDate(this.date);
        },
    },
    methods: {
        submit() {
            this.$emit('onSubmit', this.form);
        },
        formatDate(date) {
            if (!date) return null;
            return formatarDataBarra(date);
        },
    },
};
</script>
<style scoped lang="scss">
.form-footer {
    justify-content: flex-end;
}
.v-form .v-btn {
    margin-top: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
}
@media screen and (max-width: 600px) {
    .form-footer {
        justify-content: center;
    }
    .v-form .v-btn {
        width: calc(100% - 1.25rem);
        padding: 0;
        margin-right: 0;
    }
}
</style>